import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const PreloaderWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6001;
  animation: ${({ fadeOutAnimation }) =>
      fadeOutAnimation ? fadeOut : 'none'}
    0.5s forwards;
`;

const SkCubeGrid = styled.div`
  width: 3.857rem;
  height: 3.857rem;
  display: inline-block;
`;

const SkCube = styled.div`
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  animation: ${keyframes`
    0%, 70%, 100% {
      transform: scale3D(1, 1, 1);
    }
    35% {
      transform: scale3D(0, 0, 1);
    }
  `} 1.3s infinite ease-in-out;
  animation-delay: ${({ delay }) => delay || '0s'};
`;

const Preloader = () => {
  const [fadeOutAnimation, setFadeOutAnimation] = React.useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOutAnimation(true);
      setTimeout(() => {
        const preloader = document.querySelector('.preloader');
        if (preloader) {
          preloader.style.display = 'none';
        }
      }, 500);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <PreloaderWrapper fadeOutAnimation={fadeOutAnimation} className="preloader">
      <SkCubeGrid>
        {Array.from({ length: 9 }).map((_, i) => (
          <SkCube key={i} delay={`${i * 0.1}s`} />
        ))}
      </SkCubeGrid>
    </PreloaderWrapper>
  );
};

export default Preloader;
