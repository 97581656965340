import React,{useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {load} from '@cashfreepayments/cashfree-js';
import { useNavigate } from "react-router-dom";
import card1 from "../images/card1.png";
import card2 from  "../images/card2.png";




const PaymentForm = () => {
    let cashfree;

    let insitialzeSDK = async function () {
  
      // cashfree = await load({
      //   mode: "sandbox",
      // })
      cashfree = await load({
        mode: "production",
      })
    }
  
    // insitialzeSDK();
  
    const navigate = useNavigate();
  
    const [paymentOrderId, setPaymentOrderId] = useState("new");
    const [formData, setFormData] = useState({
        firstName: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        amount: "",
        phoneNumber: "",
        country: "",
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
    };
    

    const validateForm = (formData) => {
      const errors = {};
  
      if (!formData.firstName.trim()) {
        errors.firstName = "First name is required.";
      }
  
      if (!formData.email.trim()) {
        errors.email = "Email is required.";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        errors.email = "Invalid email address.";
      }
  
      // if (!formData.address.trim()) {
      //   errors.address = "Address is required.";
      // }
  
      // if (!formData.city.trim()) {
      //   errors.city = "City is required.";
      // }
  
      if (!formData.state.trim()) {
        errors.state = "State is required.";
      }
  
      if (!formData.zipCode.trim()) {
        errors.zipCode = "Zip code is required.";
      } else if (!/^\d{5}(-\d{4})?$/.test(formData.zipCode)) {
        errors.zipCode = "Invalid zip code.";
      }
  
      if (!formData.amount.trim()) {
        errors.amount = "Amount is required.";
      } else if (isNaN(formData.amount) || parseFloat(formData.amount) <= 0) {
        errors.amount = "Amount must be a positive number.";
      }
  
      if (!formData.phoneNumber.trim()) {
        errors.phoneNumber = "Phone number is required.";
      } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
        errors.phoneNumber = "Invalid phone number (must be 10 digits).";
      }
  
      if (!formData.country.trim()) {
        errors.country = "Country is required.";
      }
  
      return errors;
    };

    function generateCustomerId(firstName, phoneNumber) {
      const sanitizedFirstName = firstName.trim().toLowerCase().replace(/\s+/g, "_"); // Remove spaces and lowercase
      const last4Digits = phoneNumber.slice(-4); // Get the last 4 digits of the phone number
      return `${sanitizedFirstName}_${last4Digits}`;
    }

    const getSessionId = async () => {
      let payload ={
        order_amount: formData.amount,
        order_currency: "INR",
        customer_details: {
            customer_id: generateCustomerId(formData.firstName, formData.phoneNumber),
            customer_name: formData.firstName,
            customer_email: formData.email,
            customer_phone: formData.phoneNumber
        },
        order_note: ""
      }
      try {
        let res = await axios.post("https://srv657211.hstgr.cloud/createorder", payload);
        
        if(res.data && res.data.payment_session_id){
  
          console.log(res.data)
          setPaymentOrderId(res.data.order_id);
          return {"sessionId":res.data.payment_session_id,"orderId":res.data.order_id}
        }
      } catch (error) {
        console.log(error)
      }
    }
    
    const verifyPayment = async (orderId) => {
      console.log("orderId",orderId);
      console.log("paymentOrderId",paymentOrderId)
      try {
        
        let res = await axios.post("https://srv657211.hstgr.cloud/verify", {
              orderId: orderId
        })
  
        if(res && res.data){
          console.log("payment verified my console",res.data)
        }
  
      } catch (error) {
        console.log(error)
      }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        insitialzeSDK();
        console.log("formData",formData);

        const validationErrors = validateForm(formData);
        setErrors(validationErrors);

        console.log("erros",validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                let createOrderData = await getSessionId();
          
                const {sessionId,orderId} = createOrderData;
          
                if (!sessionId) {
                  console.error("Session ID not retrieved");
                  return;
                }
          
                let checkoutOptions = {
                  paymentSessionId : sessionId,
                  redirectTarget:"_modal",
                }
          
                cashfree.checkout(checkoutOptions).then((result) => {
                  
                  if(result.error){
                      // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
                      console.log("User has closed the popup or there is some payment error, Check for Payment Status");
                      console.log(result.error);
                      verifyPayment(orderId);
                      navigate(`/failure?orderId=${orderId}`);
                  }
                  if(result.redirect){
                      // This will be true when the payment redirection page couldnt be opened in the same window
                      // This is an exceptional case only when the page is opened inside an inAppBrowser
                      // In this case the customer will be redirected to return url once payment is completed
                      console.log("Payment will be redirected");
                      verifyPayment(orderId);
                  }
                  if(result.paymentDetails){
                      // This will be called whenever the payment is completed irrespective of transaction status
                      console.log("Payment has been completed, Check for Payment Status");
                      console.log(result.paymentDetails.paymentMessage);
                      verifyPayment(orderId);
                      navigate(`/success?orderId=${orderId}`);
                  }
                  
                });
            } catch (error) {
                console.log(error)
            }
        } else {
          // alert("Please enter valid card details.");
        }
    };
    
  return (
    <Header>
      <ParentContainer>
      <Title>BILLING ADDRESS</Title>
      <Container>
        <Section>
          <Form>
            <Label>
              First name
              <Input
                type="text"
                name="firstName"
                placeholder="Enter First name"
                value={formData.firstName}
                onChange={handleChange}
              />
              {errors.firstName && <div style={error}>{errors.firstName}</div>}
            </Label>
            <Label>
              Email
              <Input 
                type="text"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <div style={error}>{errors.email}</div>}
            </Label>
            <Label>
              Address
              <Input
                type="text"
                name="address"
                placeholder="Enter address"
                value={formData.address}
                onChange={handleChange}
              />
              {/* {errors.address && <div style={error}>{errors.address}</div>} */}
            </Label>
            <Label>
              City
              <Input 
                type="text"
                name="city"
                placeholder="Enter City"
                value={formData.city}
                onChange={handleChange}
              />
            </Label>
            <ZipContainer>
              <Label>
                State
                <Input  
                  type="text"
                  name="state"
                  placeholder="Enter state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                />
                {errors.state && <div style={error}>{errors.state}</div>}
              </Label>
              <Label>
                Zip code
                <NumberInput 
                    type="number"
                    name="zipCode"
                    placeholder="Zip code"
                    value={formData.zipCode}
                    onChange={handleChange}
                />
                {errors.zipCode && <div style={error}>{errors.zipCode}</div>}
              </Label>
              
            </ZipContainer>
          </Form>
        </Section>
        <Section>
          <Form>
            {/* <Label>
              Last name
              <Input
                type="text"
                name="lastName"
                placeholder="Enter Last name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Label>   */}
            <Label>
              Amount
              <Input
                type="number"
                name="amount"
                placeholder="Enter Amount"
                value={formData.lastName}
                onChange={handleChange}
              />  
              {errors.amount && <div style={error}>{errors.amount}</div>}
            </Label>
            <Label>
              Phone number
              <Input 
                type="text"
                name="phoneNumber"
                placeholder="+911234567890"
                maxLength="13"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
              {errors.phoneNumber && <div style={error}>{errors.phoneNumber}</div>}
            </Label>
            <Label>
              Country
              <Input
                type="text"
                name="country"
                placeholder="Enter country"
                value={formData.country}
                onChange={handleChange}
              />  
              {errors.country && <div style={error}>{errors.country}</div>}
            </Label>
            <Label>Accepted Card</Label>
            <div style={{marginBottom:"0.5rem"}}>
              <img src={card1} width="100" alt="Visa" />
              <img src={card2} width="50" alt="Mastercard" />
            </div>
          </Form>
          <SubmitButton type="submit" value="Proceed to Checkout" onClick={handleSubmit}/>
        </Section>
      </Container>
      </ParentContainer>
    </Header>
  );
};

export default PaymentForm;

const error = {
  "color": "red",
  "fontSize": '0.875rem'
}

const Header = styled.header`
  width: 100vw;
  min-height: 100vh;
  background: #34495e;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  gap: 1.5rem;

  @media only screen and (max-width: 770px) {
    flex-direction: column;
    align-items: center;
  }
`;
const ParentContainer = styled.div`
  background: white;
  max-width: 800px;
  min-height: 500px;
`;


const Section = styled.div`
  flex-basis: 50%;
  padding: 0 1rem;
`;

const Form = styled.form`
  padding: 1rem;
`;

const Title = styled.h3`
  margin-bottom: 0rem;
  margin-top: 2rem;
  color: #2c3e50;
  font-weight: bold;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem 0.7rem;
  margin: 0.5rem 0;
  outline: none;
  border: 1px solid #ccc;
`;

const ZipContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: #2c3e50;
  text-align: left;
  margin-bottom:0.5rem;
`;

const Select = styled.select`
  padding: 0.5rem 0.7rem;
  border: 1px solid #ccc;
  margin-top: 0.5rem;
`;

const NumberInput = styled(Input)`
  margin-left: 0;
`;

const SubmitButton = styled.input`
  width: 100%;
  padding: 0.7rem 1.5rem;
  background: #34495e;
  color: white;
  border: none;
  outline: none;
  margin-top: 1rem;
  cursor: pointer;
  font-weight: bold;
  text-align: center;

  &:hover {
    background: #2c3e50;
  }
`;
