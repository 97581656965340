import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CardPaymentPage from './components/cardPaymentPage';
import PaymentSuccessPage from './components/paymentSuccessPage';
import PaymentFailurePage from './components/paymentFailurePage';
import Pickles from './components/pickles';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Pickles />} /> */}
        <Route path="/" element={<CardPaymentPage />} />
        <Route path="/success" element={<PaymentSuccessPage />} />
        <Route path="/failure" element={<PaymentFailurePage />} />
      </Routes>
    </Router>
  );
}


export default App;
