// import React from "react";
// import { useSearchParams } from "react-router-dom";
// import styled from "styled-components";

// function PaymentSuccessPage() {
//   const [searchParams] = useSearchParams();
//   const orderId = searchParams.get("orderId");

//   return (
//     <Container>
//       <Title>Payment Successful!</Title>
//       <Message>Your order ID is: <OrderId>{orderId}</OrderId></Message>
//       <Button onClick={() => window.location.href = "/"}>Go to Homepage</Button>
//     </Container>
//   );
// }

// export default PaymentSuccessPage;

// const Container = styled.div`
//   text-align: center;
//   margin-top: 10%;
//   padding: 20px;
//   border-radius: 10px;
//   background-color: #fff;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//   max-width: 400px;
//   margin: 50px auto;
// `;

// const Title = styled.h1`
//   color: #28a745;
// `;

// const Message = styled.p`
//   font-size: 16px;
//   color: #333;
// `;

// const OrderId = styled.strong`
//   color: #555;
// `;

// const Button = styled.button`
//   margin-top: 20px;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   background-color: #007bff;
//   color: white;
//   cursor: pointer;
//   font-size: 16px;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #0056b3;
//   }
// `;

import React from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

function SuccessPage() {
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("orderId");
    return (
      <>
      <Header/>
      <Container>
        <Card>
            <CheckIcon>
                <CheckMark />
            </CheckIcon>
            <Title>Payment succeeded!</Title>
            <Subtitle>Your transaction was completed successfully. Thank you for your purchase!</Subtitle>
            <Subtitle>TransactionId: <BoldText>{orderId}</BoldText></Subtitle>
            <Button onClick={() => (window.location.href = "/")}>
            Go to Your Dashboard
            </Button>
        </Card>
        </Container>
        <Footer/>
      </>
        
    );
}

export default SuccessPage;


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff; /* Full page white background */
  font-family: "Arial", sans-serif;
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Smooth shadow */
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const CheckIcon = styled.div`
  width: 80px;
  height: 80px;
  background-color: #28a745;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 0 auto 20px auto;
  position: relative;
`;

const CheckMark = styled.span`
    width: 25px;
    height: 50px;
    border: solid #ffffff;
    border-width: 0 6px 6px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 5%;
`;

const Title = styled.h1`
  font-size: 24px;
  margin: 10px 0;
  color: #333333; /* Dark gray text for the title */
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #666666; /* Medium gray text for subtitle */
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838; /* Slightly darker green on hover */
  }
`;

const BoldText = styled.span`
  font-weight: bold;
  color:"#000000";
`;