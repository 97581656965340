import React from "react";
import styled from "styled-components";
import logo from "../images/VRN-Logo.png"

// Styled components
const FooterContainer = styled.div`
    background-color: #181a1c;
    color: white;
    text-align: center;
    font-family: 'Playfair Display', serif;
`;

const FooterContent = styled.div`
    display: grid;
    grid-template-columns: 30% 20% 20% 25%;
    gap: 20px;
    align-items: start;
    padding: 0% 8%;
    padding-top: 35px;
    text-align: left;
    align-itmes: left;
`;

const FooterColumn = styled.div`
    margin: 10px;
`;

const SectionTitle = styled.h3`
    margin-bottom: 10px;
`;

const FooterLink = styled.a`
    color:  #898989;
    font-weight: 400;
    text-decoration: none;
    font-size: 14px;
    &:hover {
        color: #f4a01b;
    }
`;
const Paragraph = styled.p`
    font-size: 14px;
    color:  #898989;
    font-weight: 400;
`;

const SocialIcons = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    max-width: 40%;
    // margin-left: auto;
    // margin-right: auto;
`;

const SocialLink = styled.a`
    background-color: #2b2b2b;
    border-radius: 50%; 
    padding: 10px; 
    display: inline-block;
    color: white;
    text-decoration: none;
    font-size: 20px;
    transition: background-color 0.3s ease;
    
    &:hover {
        background-color: #f4a01b;
    }

    svg {
        width: 18px;
        height: 18px;
    }
`;

const Copyright = styled.div`
    margin-top: 20px;
    font-size: 18px;
    background: #2b2b2b;
    padding: 25px;
    color: #898989;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterColumn>
          <img
            alt="VRN Foods Logo"
            height="80"
            src={logo}
            width="130"
          />
          <Paragraph>
            A name synonymous with culinary excellence, a brand that has been
            delighting taste buds for generations.
          </Paragraph>
          <SocialIcons>
            <SocialLink href="#" aria-label="Facebook">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.139 155.139">
                <path
                  d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184 c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452v20.341H37.29v27.585h23.814v70.761H89.584z"
                  fill="#FFFFFF"
                />
              </svg>
            </SocialLink>
            <SocialLink href="#" aria-label="Twitter">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
                <path
                  d="M302.973,57.388c-4.87,2.16-9.877,3.983-14.993,5.463c6.057-6.85,10.675-14.91,13.494-23.73..."
                  fill="#FFFFFF"
                />
              </svg>
            </SocialLink>
            <SocialLink href="#" aria-label="Google Plus">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 604.35 604.35">
                <path
                  d="M516.375,255v-76.5h-51V255h-76.5v51h76.5v76.5h51V306h76.5v-51H516.375z M320.025,341.7..."
                  fill="#FFFFFF"
                />
              </svg>
            </SocialLink>
          </SocialIcons>
        </FooterColumn>
        <FooterColumn>
          <SectionTitle>QUICK LINKS</SectionTitle>
          <Paragraph>
            <FooterLink href="#home">HOME</FooterLink>
          </Paragraph>
          <Paragraph>
            <FooterLink href="#about">ABOUT</FooterLink>
          </Paragraph>
          <Paragraph>
            <FooterLink href="#contact">CONTACT</FooterLink>
          </Paragraph>
          <Paragraph>
            <FooterLink href="#policy">CANCELLATION AND REFUND POLICY</FooterLink>
          </Paragraph>
          <Paragraph>
            <FooterLink href="#privacy">PRIVACY POLICY</FooterLink>
          </Paragraph>
          <Paragraph>
            <FooterLink href="#terms">TERMS AND CONDITIONS</FooterLink>
          </Paragraph>
        </FooterColumn>
        <FooterColumn>
          <SectionTitle>CATEGORIES</SectionTitle>
          <Paragraph>
            <FooterLink href="#basmati">BASMATI RICE</FooterLink>
          </Paragraph>
          <Paragraph>
            <FooterLink href="#spices">SPICES</FooterLink>
          </Paragraph>
          <Paragraph>
            <FooterLink href="#pickles">PICKLES</FooterLink>
          </Paragraph>
          <Paragraph>
            <FooterLink href="#animal-feed">ANIMAL FEED</FooterLink>
          </Paragraph>
          <Paragraph>
            <FooterLink href="#minerals">MINERALS</FooterLink>
          </Paragraph>
          <Paragraph>
            <FooterLink href="#metals">METALS</FooterLink>
          </Paragraph>
        </FooterColumn>
        <FooterColumn>
          <SectionTitle>CONTACT US</SectionTitle>
          <Paragraph>
            Address: 402, Ganga Residency Banjara Hills Rd No 3 Hyderabad,
            Telengana
          </Paragraph>
          <Paragraph>Phone: + (91) 8833556719</Paragraph>
          <Paragraph>Email: Info@vrnfoods.com</Paragraph>
        </FooterColumn>
      </FooterContent>
      <Copyright>
        © 2024 All Rights Reserved. Development by <span style={{color:"#f4a01b"}}>VRN Foods</span>
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
