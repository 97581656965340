import React from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

function FailurePage() {
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("orderId");

    return (
        <Container>
            <Card>
                <ErrorIcon>
                <CrossMark />
                <CrossMark />
                </ErrorIcon>
                <Title>Payment Failed</Title>
                <Subtitle>
                Unfortunately, your transaction could not be processed. Please try
                again later.
                </Subtitle>
                <Subtitle>
                TransactionId: <BoldText>{orderId}</BoldText>
                </Subtitle>
                <Button onClick={() => (window.location.href = "/retry")}>
                Retry Payment
                </Button>
            </Card>
        </Container>
  );
}

export default FailurePage;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff; /* Full page white background */
  font-family: "Arial", sans-serif;
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Smooth shadow */
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const ErrorIcon = styled.div`
  width: 80px;
  height: 80px;
  background-color: #dc3545; /* Red background for failure */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px auto;
  position: relative;
`;

const CrossMark = styled.span`
  display: inline-block;
  width: 40px;
  height: 6px;
  background-color: #ffffff; /* White cross */
  position: absolute;

  &:nth-child(1) {
    transform: rotate(45deg); /* First line of the cross */
  }

  &:nth-child(2) {
    transform: rotate(-45deg); /* Second line of the cross */
  }
`;

const Title = styled.h1`
  font-size: 24px;
  margin: 10px 0;
  color: #333333; /* Dark gray text for the title */
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #666666; /* Medium gray text for subtitle */
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #dc3545; /* Red button for failure */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c82333; /* Slightly darker red on hover */
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;