import React from 'react';
import styled from 'styled-components';
import logo from "../images/VRN-Logo.png";

// Styled components
const Background = styled.div`
  background-image: url(${require('../images/3.png')});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  color: white;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 0; /* To ensure it's on top of the background */
  }
`;

const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  background: rgba(0, 0, 0, 0.5);
  position: fixed; /* Makes the navbar fixed at the top */
  top: 0; /* Aligns it to the top */
  left: 0; /* Ensures it stretches across the full width */
  width: 100%; /* Ensures the navbar spans the entire width */
  z-index: 10; /* Makes sure the navbar is above other content */
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 70px;
  width: 125px;
`;

const NavLink = styled.a`
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-size: 18px;
  position: relative;
  padding-bottom: 5px;
  transition: color 0.3s ease, border-bottom 0.3s ease;

  &:hover {
    border-bottom: 2px solid #f4a01b;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background: #f4a01b;
    transition: all 0.5s ease-out;
  }

  &:hover:before {
    width: 100%;
  }
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;


  /* Ensure the dropdown content becomes visible on hover */
  &:hover .dropdown-content {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: opacity 0.45s ease-out, transform 0.45s ease-out;
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  background-color: black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 0; /* Fix the dropdown's position */
  opacity: 0;
  visibility: hidden;
  transform: translateX(-20px); /* Initially hidden off-screen */
  transition: all 0.45s ease-out;

  margin-top: 40px; 
`;

const DropdownItem = styled.a`
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;

  &:hover {
    color: #f4a01b;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Title = styled.h1`
  font-family: 'Playfair Display', serif;
  font-size: 72px;
  margin: 0;
`;

const Text = styled.p`
  font-size: 18px;
  margin: 10px 0 0;
`;


// Main component
const Header = () => {
  return (
    <Background>
      <Navbar>
        <Logo>
          <LogoImage
            alt="VRN Foods Logo"
            src={logo}
          />
        </Logo>
        <div>
          <NavLink href="#">HOME</NavLink>
          <NavLink href="#">ABOUT</NavLink>
          <Dropdown>
            <NavLink href="#">PRODUCTS</NavLink>
            <DropdownContent className="dropdown-content">
              <DropdownItem href="#">BASMATI RICE</DropdownItem>
              <DropdownItem href="#">SPICES</DropdownItem>
              <DropdownItem href="#">PICKLES</DropdownItem>
              <DropdownItem href="#">ANIMAL FEED</DropdownItem>
              <DropdownItem href="#">OTHERS</DropdownItem>
            </DropdownContent>
          </Dropdown>
          <NavLink href="#" style={{marginRight:"200px"}}>CONTACT</NavLink>
        </div>
      </Navbar>
      <Content>
        <Title>Pickles</Title>
        <Text>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </Text>
      </Content>
    </Background>
  );
};

export default Header;



